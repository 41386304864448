import i18n from "./i18n";
import { Helmet } from "react-helmet";
import axios from "axios";

import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import { getURL } from "./utils";
import NavLinkType from "./models/NavLinkType";
import "./App.css"

const LastDocuments = React.lazy(() => import("./pages/LastDocuments"));
const AllDocuments = React.lazy(() => import("./pages/AllDocuments"));
const Documents = React.lazy(() => import("./pages/Documents"));
const RequestForInformation = React.lazy(() => import("./pages/RequestForInformation"),);
const LayoutMain = React.lazy(() => import("./components/LayoutMain"));
const LayoutDashboard = React.lazy(() => import("./components/LayoutDashboard"),);
const LanguageSelection = React.lazy(() => import("./pages/LanguageSelection"));
const Home = React.lazy(() => import("./pages/Home"));
const Page404 = React.lazy(() => import("./pages/Page404"));
const ThankYou = React.lazy(() => import("./pages/ThankYou"));
const Search = React.lazy(() => import("./pages/Search"));

export default function App() {
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const [currentLanguage, setCurrentLanguage] = useState();
	const [currentMarket, setCurrentMarket] = useState();
	const [latinLanguage, setLatinLanguage]: [boolean | undefined, any] = useState();
	const [navLinks, setNavLinks]: [Array<NavLinkType>, any] = useState([]);

	useEffect(() => {
		try {
			document?.getElementById("favicon")?.setAttribute("href", process.env.REACT_APP_FAVICON_SRC!)
			setCurrentLanguage(JSON.parse(`${localStorage.getItem("Language")}`).attributes.field_iso_code_language);
			setCurrentMarket(JSON.parse(`${localStorage.getItem("Country")}`).market.attributes.field_iso_code_market);

			if (!localStorage.getItem("field_content_set_bml")) {
				throw new Error("");
			}

			if (currentLanguage && currentMarket) {
				document.documentElement.setAttribute("lang", currentLanguage);
				if (pathname === "/") {
					navigate(currentMarket + "/" + currentLanguage);
				}
				else {
					if (pathname !== "/choose-your-market") {
						let path = pathname.split("/documents");
						let path1 = path[0]?.split("/");
						let path2 = path[1]?.split("/");

						// If the first part of the path is well formatted
						if (path1.length >= 3) {
							// If the market or the language are different
							if (path1[1] !== currentMarket || path1[2] !== currentLanguage) {
								if (path2) {
									navigate(`/choose-your-market?different=true&market=${path1[1]}&language=${path1[2]}${path2[1] ? "&link=" + path2[1] : ""}${path2[2] ? "&sublink=" + path2[2] : ""}${path2[3] ? "&subsublink=" + path2[3] : ""}`);
								}
								else {
									navigate(`/choose-your-market?different=true&market=${path1[1]}&language=${path1[2]}&link=${path1[3]}`);
								}
							}
						}
						else {
							navigate("/choose-your-market");
						}
					}
				}

				// Fetching Navlinks
				axios.get(`${process.env.REACT_APP_BASEURL}/api/json/broadview_menu/${localStorage.getItem("field_content_set_bml")}/${JSON.parse(localStorage.getItem("Language")!).attributes.field_iso_code_language}/${JSON.parse(localStorage.getItem("Country")!).market.attributes.field_iso_code_market}`)
					.then(function (response) {
						let data = response.data.data;

						let parsedData: Array<NavLinkType> = [];
						// Level 0
						for (let i = 0; i < data?.length; i++) {
							let parent: NavLinkType = {
								productName: data[i].productName,
								productId: data[i].productTid,
								productLink: data[i].productParamUrl,
								0: [],
							};
							// Level 1
							for (let k = 0; k < data[i]["0"]?.length; k++) {
								let navlink: NavLinkType = {
									productName: data[i]["0"][k].productName,
									productId: data[i]["0"][k].productTid,
									productLink: data[i]["0"][k].productParamUrl || getURL(data[i]["0"][k].productParamUrl),
									productDesc: data[i]["0"][k].productDesc ? data[i]["0"][k].productDesc : "",
									0: [],
									parent: parent,
								};
								// Level 2
								for (let l = 0; l < data[i]["0"][k]["categorie"]?.length; l++) {
									let sub_navlink: NavLinkType = {
										productName: data[i]["0"][k]["categorie"][l].categoriaName,
										productId: data[i]["0"][k]["categorie"][l].categoriaTid,
										productLink: data[i]["0"][k]["categorie"][l].categoriaParamUrl || getURL(data[i]["0"][k]["categorie"][l].categoriaName),
										productDesc: data[i]["0"][k]["categorie"][l].productDesc ? data[i]["0"][k]["categorie"][l].productDesc : "",
										0: [],
										parent: navlink,
									}
									// Level 3
									for (let j = 0; j < data[i]["0"][k]["categorie"][l]["sottocategorie"]?.length; j++) {
										let sub_sub_navlink: NavLinkType = {
											productName: data[i]["0"][k]["categorie"][l]["sottocategorie"][j].categoriaName,
											productId: data[i]["0"][k]["categorie"][l]["sottocategorie"][j].categoriaTid,
											productLink: data[i]["0"][k]["categorie"][l]["sottocategorie"][j].categoriaParamUrl,
											parent: sub_navlink,
										};

										sub_navlink["0"]?.push(sub_sub_navlink);
									}
									navlink["0"]?.push(sub_navlink);
								}
								parent["0"]?.push(navlink);
							}
							parsedData.push(parent);
						}
						setNavLinks(parsedData);
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		}
		catch (err) {
			i18n.changeLanguage("en")
			if (!localStorage.getItem("Country") || !localStorage.getItem("Language")) {
				let path = pathname.split("/documents");
				let path1 = path[0]?.split("/");
				let path2 = path[1]?.split("/");;

				// If the first part of the path is well formatted
				if (path1.length >= 3) {
					// If the market or the language are different
					if (path2) {
						navigate(`/choose-your-market?different=true&market=${path1[1]}&language=${path1[2]}${path2[1] ? "&link=" + path2[1] : ""}${path2[2] ? "&sublink=" + path2[2] : ""}${path2[3] ? "&subsublink=" + path2[3] : ""}`);
					}
					else {
						navigate(`/choose-your-market?different=true&market=${path1[1]}&language=${path1[2]}&link=${path1[3]}`);
					}
				}
				else {
					navigate("/choose-your-market");
				}
			}
			localStorage.clear();
		}
	}, [currentLanguage, currentMarket, pathname])

	useEffect(() => {
		// If the current language is latin
		//console.log("currentLanguage " +currentLanguage)
		if (currentLanguage === "en" || 
			currentLanguage === "de" ||
			currentLanguage === "es" ||
			currentLanguage === "fr" ||
			currentLanguage === "it" ||
			currentLanguage === "nl" ||
			currentLanguage === "pt-pt" ) {
			setLatinLanguage(true);
		}
		else {
			setLatinLanguage(false);
		}
		i18n.changeLanguage(`${currentLanguage}`.replace("-", ""));
	}, [currentLanguage]);

	return (
		<div className={`App ${process.env.REACT_APP_BRAND_INTERNAL_NAME} ${latinLanguage ? "latin" : ""}`}>
			<Helmet>
				<meta charSet="utf-8" />
				<title></title>
				<meta name="description" content=""></meta>
				<link rel="canonical" href={process.env.REACT_APP_SITE_URL} />
			</Helmet>
			<Routes>
				<Route
					path="/"
					element={
						<LanguageSelection
							setCurrentLanguage={setCurrentLanguage}
							setCurrentMarket={setCurrentMarket}
						/>
					}
				/>
				<Route
					path="/choose-your-market"
					element={
						<LanguageSelection
							setCurrentLanguage={setCurrentLanguage}
							setCurrentMarket={setCurrentMarket}
						/>
					}
				/>
				<Route
					element={
						<LayoutMain setCurrentLanguage={setCurrentLanguage} />
					}
					path={`/:market/:language`}
				>
					<Route
						element={
							<LayoutDashboard navLinks={navLinks} />
						}
						path={`/:market/:language`}
					>
						<Route index element={<Home />} />
						<Route path={`/:market/:language/thank-you`} element={<ThankYou />} />

						<Route
							path={`/:market/:language/all-documents/*`}
							element={<AllDocuments />}
						/>
						<Route
							path={`/:market/:language/last-documents`}
							element={<LastDocuments />}
						/> 
						<Route
							path={`/:market/:language/request-for-information`}
							element={<RequestForInformation />}
						/>
						<Route
							path={`/:market/:language/search/:param`}
							element={<Search></Search>}
						/>
						<Route
							path={`/:market/:language/documents`}>
							{
								navLinks?.map((navLink: NavLinkType, i: number) => (
									navLink["0"]?.map((child: NavLinkType, i: number) => (
										child["0"]?.length === 0 ?
											<Route
												key={child.productLink}
												path={`/:market/:language/documents/${child.productLink}`}
												element={<Documents
													path={child.productLink}
													pathDisplayName={child.productName}
													subpath={null}
													subpathDisplayName={null}
													subsubpath={null}
													subsubpathDisplayName={null}
												/>}
											/>
											:
											<Route
												key={child.productLink}
												path={`/:market/:language/documents/${child.productLink}`}
											>
												{
													child["0"]?.map((subChild: NavLinkType, i: number) => (
														subChild["0"]?.length === 0 ?
															<Route
																key={subChild.productLink}
																path={`/:market/:language/documents/${child.productLink}/${subChild.productLink}`}
																element={<Documents path={child.productLink} pathDisplayName={child.productName} subpath={subChild.productLink} subpathDisplayName={subChild.productName} subsubpath={null} subsubpathDisplayName={null} />}
															/>
															:
															<Route
																key={subChild.productLink}
																path={`/:market/:language/documents/${child.productLink}/${subChild.productLink}`}
															>
																{
																	subChild["0"]?.map((subSubChild: NavLinkType, i: number) => (
																		<Route
																			key={subSubChild.productLink}
																			path={`/:market/:language/documents/${child.productLink}/${subChild.productLink}/${subSubChild.productLink}`}
																			element={<Documents path={child.productLink} pathDisplayName={child.productName} subpath={subChild.productLink} subpathDisplayName={subChild.productName} subsubpath={subSubChild.productLink} subsubpathDisplayName={subSubChild.productName} />}
																		/>
																	))
																}
															</Route>
													))
												}
											</Route>
									))
								))
							}
							<Route
								path={`/:market/:language/documents/*`}
								element={<Documents path={null}
									pathDisplayName={null}
									subpath={null}
									subpathDisplayName={null}
									subsubpath={null}
									subsubpathDisplayName={null} />} />
						</Route>
						<Route path={`/:market/:language/*`} element={<Page404 />} />
					</Route>
					<Route path={`/:market/:language/*`} element={<Page404 />} />
				</Route>
				<Route path="*" element={<Page404 />} />
			</Routes>
		</div>
	);
}
